import {useState} from "react";
import './App.css';
import logo from './assets/logo.png'

function App() {
    const [alert,setAlert] = useState(false);

   const onSubmit = (e) => {
       e.preventDefault();
       setAlert(true)
       setTimeout(() => {
           setAlert(false);
       },3000)
   }

  return (
    <div className="App">
      <img src={logo}/>
      <form onSubmit={onSubmit}>
          <label htmlFor="username">
              <input type='text' placeholder='Username' className='form-control mb-3' style={{textAlign:"center"}}/>
              <input type='password' placeholder='Password' className='form-control mb-3' style={{textAlign:"center"}}/>
              <button type="submit" className="btn btn-dark mb-3">Login</button>
          </label>
      </form>
        {alert && (
            <div className='container'>
            <div className='alert alert-danger'>
                User information is incorrect
            </div>
            </div>
        )}
    </div>
  );
}

export default App;
